import React, { Component } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Container, Dropdown, DropdownItem, DropdownMenu, MenuMenu, Menu, MenuItem, Form, FormInput } from 'semantic-ui-react';


class Layout extends Component {
    state = { search: '' }

    handleSearch = () => {
        const { search } = this.state;
        fetch(`https://challenge-api.duncanwatts.com/api/Lookup/${search}`).catch((error) => { }).then((res) => res.json()).then((json) => {
            document.location.href = json.url;
        })
    }

    searchChanged = (e) => {
        this.setState({ search: e.target.value });
    }

    render() {
        return (
            <>
                <Menu fixed='top' inverted color='red' size='massive' borderless style={{"background-color": "#e10600"}}>
                    <Container>
                        <MenuItem header as={Link} to='/'>Series Challenge</MenuItem>
                        <Dropdown simple item text='Leaderboards'>
                            <DropdownMenu>
                                <Dropdown simple item text='Current'>
                                    <DropdownMenu>
                                        <DropdownItem as={Link} to='/'>Overall</DropdownItem>
                                        <DropdownItem as={Link} to="/Road">Road</DropdownItem>
                                        <DropdownItem as={Link} to="/Rookie">Rookie</DropdownItem>
                                        <DropdownItem as={Link} to="/SportsCar">Sports Car</DropdownItem>
                                        <DropdownItem as={Link} to="/FormulaCar">Formula Car</DropdownItem>
                                        <DropdownItem as={Link} to="/Oval">Oval</DropdownItem>
                                        <DropdownItem as={Link} to="/DirtRoad">Dirt Road</DropdownItem>
                                        <DropdownItem as={Link} to="/DirtOval">Dirt Oval</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown simple item text='All Time'>
                                    <DropdownMenu>
                                        <DropdownItem as={Link} to='/AllTime'>Overall</DropdownItem>
                                        <DropdownItem as={Link} to="/Road/AllTime">Road</DropdownItem>
                                        <DropdownItem as={Link} to="/Rookie/AllTime">Rookie</DropdownItem>
                                        <DropdownItem as={Link} to="/SportsCar/AllTime">Sports Car</DropdownItem>
                                        <DropdownItem as={Link} to="/FormulaCar/AllTime">Formula Car</DropdownItem>
                                        <DropdownItem as={Link} to="/Oval/AllTime">Oval</DropdownItem>
                                        <DropdownItem as={Link} to="/DirtRoad/AllTime">Dirt Road</DropdownItem>
                                        <DropdownItem as={Link} to="/DirtOval/AllTime">Dirt Oval</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </DropdownMenu>
                        </Dropdown>
                        <MenuItem href='/Leagues'>Leagues</MenuItem>
                        <MenuMenu position='right' style={{ "margin": "5px" }}>
                            <Form onSubmit={this.handleSearch} size='big'>
                                <FormInput placeholder='Name / iRacing Id...' name='search' type='text' action={{ color: 'black', icon: 'search' }} onChange={this.searchChanged} />
                            </Form>
                        </MenuMenu>
                    </Container>
                </Menu>
                <Container className='main'>
                    <div style={{ "display": "flex", "justify-content": "flex-end" }}>
                        <a href="https://www.buymeacoffee.com/duncasaur"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=☕&slug=duncasaur&button_colour=FFDD00&font_colour=000000&font_family=Bree&outline_colour=000000&coffee_colour=ffffff" alt='buymeacoffee' /></a>
                    </div>
                    <Outlet />
                    <div class="footer">&nbsp;</div>
                </Container>
            </>
        );
    }
}

export default Layout;