import React from 'react';
import { Icon } from 'semantic-ui-react';

function Position({ value }) {



    function getSuffix(number) {

        if (Math.floor(number / 10) % 10 === 1)
            return "th";
        const remainder = number % 10;
        switch (remainder) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    switch (value) {
        case 1:
            return (
                <>
                    <Icon name='trophy' color='yellow' />
                </>
            );
        case 2:
            return (
                <>
                    <Icon name='trophy' color='grey' />
                </>
            );
        case 3:
            return (
                <>
                    <Icon name='trophy' color='orange' />
                </>
            );
        default:
            return <>{value}<sup>{getSuffix(value)}</sup></>;
    }
}

export default Position;