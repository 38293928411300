import React from 'react';
import { Table, TableRow, TableBody, TableCell, Segment, Button, Label, Grid, GridColumn, Icon, Divider } from 'semantic-ui-react';
import IRacingIcon from './IRacingIcon';
import { Link } from 'react-router-dom';

function CategoryResult({ driverId, category, selectedCategory, results, rankings }) {

    var categoryLookup = {
        "road": "Road",
        "formula_car": "FormulaCar",
        "sports_car": "SportsCar",
        "oval": "Oval",
        "dirt_road": "DirtRoad",
        "dirt_oval": "DirtOval"
    };

    var categoryNameLookup = {
        "Overall": "Overall",
        "Road": "Road",
        "Rookie": "Rookie",
        "FormulaCar": "Formula Car",
        "SportsCar": "Sports Car",
        "Oval": "Oval",
        "DirtRoad": "Dirt Road",
        "DirtOval": "Dirt Oval"
    };

    function getFontSize(rank) {
        if (rank >= 10000) {
            return "0.9625em";
        }
        if (rank >= 1000) {
            return "1.1375em"
        }
        return "1.3125em";
    }
    

    function getSuffix(number) {

        if (Math.floor(number / 10) % 10 === 1)
            return "th";
        const remainder = number % 10;
        switch (remainder) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    var filtered = results.filter(function (item) { return item.isDuncathlon && ((category === 'Rookie' && item.licence === 'Rookie') || category === 'Overall' || category === undefined || (category === 'Road' && (categoryLookup[item.category] === 'FormulaCar' || categoryLookup[item.category] === 'SportsCar' || categoryLookup[item.category] === 'Road')) || categoryLookup[item.category] === category) });

    if (filtered.length === 0) return (<></>);

    var ranking = rankings.find(r => r.category === category);

    var currentWins = filtered.reduce((acc, series) => acc + series.classes.filter(c => c.currentBestPosition === 1).length, 0);
    var currentP2s = filtered.reduce((acc, series) => acc + series.classes.filter(c => c.currentBestPosition === 2).length, 0);
    var currentP3s = filtered.reduce((acc, series) => acc + series.classes.filter(c => c.currentBestPosition === 3).length, 0);

    var allTimeWins = filtered.reduce((acc, series) => acc + series.classes.filter(c => c.bestPosition === 1).length, 0);
    var allTimeP2s = filtered.reduce((acc, series) => acc + series.classes.filter(c => c.bestPosition === 2).length, 0);
    var allTimeP3s = filtered.reduce((acc, series) => acc + series.classes.filter(c => c.bestPosition === 3).length, 0);

    var classCount = filtered.reduce((acc, series) => acc + series.classes.length, 0);


    return (
        <>
            <div style={{ "margin": "5px" }}>
                <Button as={Link} to={`/Driver/${driverId}/${category}`} color={category === selectedCategory ? 'red' : 'grey'} icon labelPosition='left' style={{ "font-weight": category === selectedCategory ? 'bold' : 'normal', "height": "40px;", "margin-bottom": "-5px", "margin-left": "-3px", "border-bottom-left-radius": "0", "border-bottom-right-radius": "0" }}>
                    <div class="icon" style={{ "display": "flex", "align-items": "center", "justify-content": "center" }}>
                        <IRacingIcon icon={category} />
                    </div>
                    <span style={{ "vertical-align": "middle" }}>{categoryNameLookup[category]}</span>
                </Button>
            </div>

            <Segment raised color={category === selectedCategory ? 'red' : 'grey'} style={{ "margin": "0" }}>
                <Label ribbon color={category === selectedCategory ? 'red' : 'lightgrey'}>All Time</Label>
                <Grid columns={2} container>
                    <GridColumn textAlign='center' verticalAlign='middle'>
                        <div class="outerchart">
                            <div class="innerchart">
                                <div id="middle-circle-mini" style={{ "font-size": getFontSize(ranking?.allTimeRank) }}>
                                    {ranking !== undefined && ranking?.allTimeRank !== null && ranking?.allTimeRank !== 0 ? 
                                    (
                                        <>
                                            {ranking?.allTimeRank?.toLocaleString()}<sup style={{"font-size": "50%"}}>{getSuffix(ranking?.allTimeRank)}</sup>
                                        </>
                                     ) : <text>-</text>}
                                </div>
                                <div id="progress-spinner-mini" style={{ "background": `conic-gradient(from 180deg, #fbbd08 ${allTimeWins / classCount * 100}%, #767676 ${allTimeWins / classCount * 100}% ${(allTimeWins + allTimeP2s) / classCount * 100}%, #f2711c ${(allTimeWins + allTimeP2s) / classCount * 100}% ${(allTimeWins + allTimeP2s + allTimeP3s) / classCount * 100}%, #F2F2F2 ${(allTimeWins + allTimeP2s + allTimeP3s) / classCount * 100}%)` }}></div>
                            </div>
                        </div>
                    </GridColumn>
                    <GridColumn textAlign='center' verticalAlign='middle'>
                        <Table compact basic='very' style={{ "font-size": "1em", "margin-top": "0px", "padding-left": "5px", "padding-right": "5px" }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Icon name='trophy' color='yellow' />
                                    </TableCell>
                                    <TableCell style={{ "font-weight": (allTimeWins > 0 ? 'bold' : 'normal'), "padding-left": "0px", "padding-top": "0px", "width": "40px" }}>
                                        {allTimeWins}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Icon name='trophy' color='grey' />
                                    </TableCell>
                                    <TableCell style={{ "font-weight": (allTimeP2s > 0 ? 'bold' : 'normal'), "padding-left": "0px", "padding-top": "0px", "width": "40px" }}>
                                        {allTimeP2s}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Icon name='trophy' color='orange' />
                                    </TableCell>
                                    <TableCell style={{ "font-weight": (allTimeP3s > 0 ? 'bold' : 'normal'), "padding-left": "0px", "padding-top": "0px", "width": "40px" }}>
                                        {allTimeP3s}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </GridColumn>
                </Grid>
                <Divider />
                <Label ribbon color={category === selectedCategory ? 'red' : 'lightgrey'}>Current Season</Label>
                <Grid columns={2} container>
                    <GridColumn textAlign='center' verticalAlign='middle'>
                        <div class="outerchart">
                            <div class="innerchart">
                            <div id="middle-circle-mini" style={{ "font-size": getFontSize(ranking?.currentRank) }}>
                                    {ranking !== undefined && ranking?.currentRank !== null && ranking?.currentRank !== 0 ? 
                                    (
                                        <>
                                            {ranking?.currentRank?.toLocaleString()}<sup style={{"font-size": "50%"}}>{getSuffix(ranking?.currentRank)}</sup>
                                        </>
                                     ) : <text>-</text>}
                                </div>
                                <div id="progress-spinner-mini" style={{ "background": `conic-gradient(from 180deg, #fbbd08 ${currentWins / classCount * 100}%, #767676 ${currentWins / classCount * 100}% ${(currentWins + currentP2s) / classCount * 100}%, #f2711c ${(currentWins + currentP2s) / classCount * 100}% ${(currentWins + currentP2s + currentP3s) / classCount * 100}%, #F2F2F2 ${(currentWins + currentP2s + currentP3s) / classCount * 100}%)` }}></div>
                            </div>
                        </div>
                    </GridColumn>
                    <GridColumn textAlign='center' verticalAlign='middle'>
                        <Table compact basic='very' style={{ "font-size": "1em", "margin-top": "0px", "padding-left": "5px", "padding-right": "5px" }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Icon name='trophy' color='yellow' />
                                    </TableCell>
                                    <TableCell style={{ "font-weight": (currentWins > 0 ? 'bold' : 'normal'), "padding-left": "0px", "padding-top": "0px", "width": "40px" }}>
                                        {currentWins}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Icon name='trophy' color='grey' />
                                    </TableCell>
                                    <TableCell style={{ "font-weight": (currentP2s > 0 ? 'bold' : 'normal'), "padding-left": "0px", "padding-top": "0px", "width": "40px" }}>
                                        {currentP2s}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Icon name='trophy' color='orange' />
                                    </TableCell>
                                    <TableCell style={{ "font-weight": (currentP3s > 0 ? 'bold' : 'normal'), "padding-left": "0px", "padding-top": "0px", "width": "40px" }}>
                                        {currentP3s}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </GridColumn>
                </Grid>
            </Segment >
        </>
    );
}

export default CategoryResult;