import React, { useEffect, useState } from 'react';
import { Grid, GridRow, GridColumn, Header } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import LicenceResult from './LicenceResult';
import CategoryResult from './CategoryResult';
import { Helmet } from 'react-helmet';

function Driver() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    
    const { driverId, category = 'Overall' } = useParams();

    
    var categoryNameLookup = {
        "Overall": "Overall",
        "Road": "Road",
        "Rookie": "Rookie",
        "FormulaCar": "Formula Car",
        "SportsCar": "Sports Car",
        "Oval": "Oval",
        "DirtRoad": "Dirt Road",
        "DirtOval": "Dirt Oval"
    };

    useEffect(() => {


        const fetchData = async () => {
            try {
                const response = await fetch(`https://challenge-api.duncanwatts.com/api/Driver/${driverId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                const data = await response.json();

                setData(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        fetchData();
    }, [driverId]);


    return (
        <>

            {loading ? (

                <p>Loading...</p>


            ) : error ? (
                <p>Error: {error}</p>
            ) : 
            (
                <>

                    <Helmet>
                        <title>{data.name} | {categoryNameLookup[category]} | Series Challenge</title>
                    </Helmet>

                    <Header size='huge'>{data.name}</Header>

                    <Grid relaxed columns={3} style={{"margin-left":"-5px", "margin-right": "-5px"}}>
                        <GridRow>
                            <GridColumn>
                                <CategoryResult driverId={driverId} category='Overall' selectedCategory={category} results={data.serieses} rankings={data.rankings} />
                            </GridColumn>
                            <GridColumn>
                                <CategoryResult driverId={driverId} category='Road' selectedCategory={category} results={data.serieses} rankings={data.rankings} />
                            </GridColumn>
                            <GridColumn>
                                <CategoryResult driverId={driverId} category='Rookie' selectedCategory={category} results={data.serieses} rankings={data.rankings} />
                            </GridColumn>
                        </GridRow>
                    </Grid>

                    <Grid relaxed columns={5} style={{"margin-left":"-5px", "margin-right": "-5px"}}>
                        <GridRow>
                            <GridColumn>
                                <CategoryResult driverId={driverId} category='SportsCar' selectedCategory={category} results={data.serieses} rankings={data.rankings} />
                            </GridColumn>
                            <GridColumn>
                                <CategoryResult driverId={driverId} category='FormulaCar' selectedCategory={category} results={data.serieses} rankings={data.rankings} />
                            </GridColumn>
                            <GridColumn>
                                <CategoryResult driverId={driverId} category='Oval' selectedCategory={category} results={data.serieses} rankings={data.rankings} />
                            </GridColumn>
                            <GridColumn>
                                <CategoryResult driverId={driverId} category='DirtRoad' selectedCategory={category} results={data.serieses} rankings={data.rankings} />
                            </GridColumn>
                            <GridColumn>
                                <CategoryResult driverId={driverId} category='DirtOval' selectedCategory={category} results={data.serieses} rankings={data.rankings} />
                            </GridColumn>
                        </GridRow>
                    </Grid>

                    <LicenceResult licence='Rookie' category={category} results={data.serieses} />
                    <LicenceResult licence='Class D' category={category} results={data.serieses} />
                    <LicenceResult licence='Class C' category={category} results={data.serieses} />
                    <LicenceResult licence='Class B' category={category} results={data.serieses} />
                    <LicenceResult licence='Class A' category={category} results={data.serieses} />
                </>
            )}            

        </>
    );
}

export default Driver;