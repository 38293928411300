import React, { useEffect, useState } from 'react';
import { Table, TableHeader, TableHeaderCell, TableRow, TableBody, TableCell } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Leagues() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {


        const fetchData = async () => {
            try {
                const response = await fetch(`https://challenge-api.duncanwatts.com/api/Leagues`);
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                const data = await response.json();
                setData(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        fetchData();
    }, []);


    return (
        <>

            <Helmet>
                <title>Leagues | Series Challenge</title>
            </Helmet>

            <Table size='large'>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell width={7}>Series</TableHeaderCell>
                        <TableHeaderCell width={3} textAlign='center'><i class="trophy icon yellow"></i></TableHeaderCell>
                        <TableHeaderCell width={3} textAlign='center'><i class="trophy icon grey"></i></TableHeaderCell>
                        <TableHeaderCell width={3} textAlign='center'><i class="trophy icon orange"></i></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>


                    {loading ? (

                        <TableRow>
                            <TableCell>Loading...</TableCell>
                        </TableRow>

                    ) : error ? (
                        <TableRow>
                            <TableCell>Error: {error}</TableCell>
                        </TableRow>
                    ) : data.leagues.map((league) => (
                        <TableRow>
                            <TableCell selectable><Link to={`/League/${league.id}`}>{league.name}</Link></TableCell>
                            {league.latestSeason.p1 !== null ?
                                (
                                    <>
                                        <TableCell selectable><Link to={`/Driver/${league.latestSeason.p1DriverId}`}>{league.latestSeason.p1}</Link></TableCell>
                                        <TableCell selectable><Link to={`/Driver/${league.latestSeason.p2DriverId}`}>{league.latestSeason.p2}</Link></TableCell>
                                        <TableCell selectable><Link to={`/Driver/${league.latestSeason.p3DriverId}`}>{league.latestSeason.p3}</Link></TableCell>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                    </>
                                )}
                        </TableRow>
                    ))}
                </TableBody>

            </Table>

        </>
    );
}

export default Leagues;