import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, Table, TableHeader, TableHeaderCell, TableRow, TableBody, TableCell, Icon } from 'semantic-ui-react';
import IRacingIcon from './IRacingIcon';
import Position from './Position';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Standings({ category = 'Overall', isCurrent = true }) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        
    const fetchData = async () => {
        try {
            const response = await fetch(`https://challenge-api.duncanwatts.com/api/Standings/${category}/${isCurrent}`);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();
            setData(data);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };
        fetchData();
    }, [category, isCurrent]);

    

    const title = category === 'SportsCar' ? "Sports Car"
                    : category === 'FormulaCar' ? "Formula Car"
                            : category === 'DirtRoad' ? "Dirt Road"
                                : category === 'DirtOval' ? "Dirt Oval"
                                    : category;


    return (
        <>

            <Helmet>
                <title>{title} {!isCurrent ? 'All Time ' : ''}| Series Challenge</title>
            </Helmet>

            <Menu pointing secondary widths={8}>
                <MenuItem active={category === "Overall"} as={Link} to={`/${!isCurrent ? 'AllTime' : ''}`}><div class="headerIcon"><IRacingIcon icon="Overall" /></div>Overall</MenuItem>
                <MenuItem active={category === "Road"} as={Link} to={`/Road${!isCurrent ? '/AllTime' : ''}`}><div class="headerIcon"><IRacingIcon icon="Road" /></div>Road</MenuItem>
                <MenuItem active={category === "Rookie"} as={Link} to={`/Rookie${!isCurrent ? '/AllTime' : ''}`}><div class="headerIcon"><IRacingIcon icon="Rookie" /></div>Rookie</MenuItem>
                <MenuItem active={category === "SportsCar"} as={Link} to={`/SportsCar${!isCurrent ? '/AllTime' : ''}`}><div class="headerIcon"><IRacingIcon icon="SportsCar" /></div>Sports Car</MenuItem>
                <MenuItem active={category === "FormulaCar"} as={Link} to={`/FormulaCar${!isCurrent ? '/AllTime' : ''}`}><div class="headerIcon"><IRacingIcon icon="FormulaCar" /></div>Formula Car</MenuItem>
                <MenuItem active={category === "Oval"} as={Link} to={`/Oval${!isCurrent ? '/AllTime' : ''}`}><div class="headerIcon"><IRacingIcon icon="Oval" /></div>Oval</MenuItem>
                <MenuItem active={category === "DirtRoad"} as={Link} to={`/DirtRoad${!isCurrent ? '/AllTime' : ''}`}><div class="headerIcon"><IRacingIcon icon="DirtRoad" /></div>Dirt Road</MenuItem>
                <MenuItem active={category === "DirtOval"} as={Link} to={`/DirtOval${!isCurrent ? '/AllTime' : ''}`}><div class="headerIcon"><IRacingIcon icon="DirtOval" /></div>Dirt Oval</MenuItem>
            </Menu>

            <Table size='large' definition>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell width={10} colSpan={2} />
                        <TableHeaderCell width={3} colSpan={3} textAlign='center' style={{ "border-left-width": "0.8px", "border-left-color": "rgba(34,36,38,0.1)", "border-left-style": "solid" }}><Link to={`/${category}/AllTime`}>All Time{!isCurrent && <Icon name='caret down' />}</Link></TableHeaderCell>
                        <TableHeaderCell width={3} colSpan={3} textAlign='center' style={{ "border-left-width": "0.8px", "border-left-color": "rgba(34,36,38,0.1)", "border-left-style": "solid" }}><Link to={`/${category}`}>Current Season{isCurrent && <Icon name='caret down' />}</Link></TableHeaderCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell width={10} colSpan={2} style={{ "font-weight": "800", "color": "#000", "font-size": "24px" }} textAlign='center'>
                            {title} {!isCurrent ? 'All Time ' : ''}Leaderboard
                        </TableHeaderCell>
                        <TableHeaderCell textAlign='center' style={{ "border-left-width": "0.8px", "border-left-color": "rgba(34,36,38,0.1)", "border-left-style": "solid" }}><i class="trophy icon yellow"></i></TableHeaderCell>
                        <TableHeaderCell textAlign='center'><i class="trophy icon grey"></i></TableHeaderCell>
                        <TableHeaderCell textAlign='center'><i class="trophy icon orange"></i></TableHeaderCell>
                        <TableHeaderCell textAlign='center' style={{ "border-left-width": "0.8px", "border-left-color": "rgba(34,36,38,0.1)", "border-left-style": "solid" }}><i class="trophy icon yellow"></i></TableHeaderCell>
                        <TableHeaderCell textAlign='center'><i class="trophy icon grey"></i></TableHeaderCell>
                        <TableHeaderCell textAlign='center'><i class="trophy icon orange"></i></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>


                    {loading ? (

                        <TableRow>
                            <TableCell colSpan={18}>Loading...</TableCell>
                        </TableRow>

                    ) : error ? (
                        <TableRow>
                            <TableCell colSpan={18}>Error: {error}</TableCell>
                        </TableRow>
                    ) : data.map((item, i) => (
                        <TableRow>
                            <TableCell textAlign='center'>
                                <Position value={i + 1} />
                            </TableCell>
                            <TableCell width={9} selectable><Link to={`/Driver/${item.driverId}/${category}`}>{item.name}</Link></TableCell>
                            <TableCell textAlign='center' style={{ "border-left-width": "0.8px", "border-left-color": "rgba(34,36,38,0.1)", "border-left-style": "solid" }}>{item.wins}</TableCell>
                            <TableCell textAlign='center'>{item.p2s}</TableCell>
                            <TableCell textAlign='center'>{item.p3s}</TableCell>
                            <TableCell textAlign='center' style={{ "border-left-width": "0.8px", "border-left-color": "rgba(34,36,38,0.1)", "border-left-style": "solid" }}>{item.currentWins}</TableCell>
                            <TableCell textAlign='center'>{item.currentP2s}</TableCell>
                            <TableCell textAlign='center'>{item.currentP3s}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>

            </Table>

        </>
    );
}

export default Standings;