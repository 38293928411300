import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout'
import Standings from './Standings'
import Driver from './Driver';
import Leagues from './Leagues';
import League from './League';
import Hosted from './Hosted';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Standings />} />
          <Route path="/Overall" element={<Standings category="Overall" isCurrent={true} />} />
          <Route path="/Road" element={<Standings category="Road" isCurrent={true} />} />
          <Route path="/Rookie" element={<Standings category="Rookie" isCurrent={true} />} />
          <Route path="/SportsCar" element={<Standings category="SportsCar" isCurrent={true} />} />
          <Route path="/FormulaCar" element={<Standings category="FormulaCar" isCurrent={true} />} />
          <Route path="/Oval" element={<Standings category="Oval" isCurrent={true} />} />
          <Route path="/DirtRoad" element={<Standings category="DirtRoad" isCurrent={true} />} />
          <Route path="/DirtOval" element={<Standings category="DirtOval" isCurrent={true} />} />
          
          <Route path="/AllTime" element={<Standings category="Overall" isCurrent={false} />} />
          <Route path="/Overall/AllTime" element={<Standings category="Overall" isCurrent={false} />} />
          <Route path="/Road/AllTime" element={<Standings category="Road" isCurrent={false} />} />
          <Route path="/Rookie/AllTime" element={<Standings category="Rookie" isCurrent={false} />} />
          <Route path="/SportsCar/AllTime" element={<Standings category="SportsCar" isCurrent={false} />} />
          <Route path="/FormulaCar/AllTime" element={<Standings category="FormulaCar" isCurrent={false} />} />
          <Route path="/Oval/AllTime" element={<Standings category="Oval" isCurrent={false} />} />
          <Route path="/DirtRoad/AllTime" element={<Standings category="DirtRoad" isCurrent={false} />} />
          <Route path="/DirtOval/AllTime" element={<Standings category="DirtOval" isCurrent={false} />} />          


          <Route path="/Leagues" element={<Leagues />} />  
          <Route path="/League/:name" element={<League />} />  
          <Route path="/League/:name/:season/:quarter" element={<League />} />          

          <Route path="/Driver/:driverId" element={<Driver category="Overall" />} />          
          <Route path="/Driver/:driverId/:category" element={<Driver />} />
          
          <Route path="/Hosted/:name" element={<Hosted />} />  
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
