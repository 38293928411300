import React from 'react';

function IRacingIcon({ icon = 'Overall' }) {

    switch (icon) {
        case 'Road':
            return (
                <>
                    <svg viewBox="0 0 16 16" focusable="false" class="chakra-icon miniIcon" style={{ "max-width": "20px", "max-height": "20px", "display": "block", "margin": "0 auto" }}><path fill="currentColor" d="M16 5C16 3.34315 14.6569 2 13 2V2H10V2C8.34315 2 7 3.34315 7 5V5V5.5H9V5V5C9 4.44772 9.44772 4 10 4H12.5C12.9659 4 13.1989 4 13.3827 4.07612C13.6277 4.17761 13.8224 4.37229 13.9239 4.61732C14 4.80109 14 5.03406 14 5.5V5.5C14 5.96594 14 6.19891 13.9239 6.38268C13.8224 6.62771 13.6277 6.82239 13.3827 6.92388C13.1989 7 12.9659 7 12.5 7H3V7C1.34315 7 0 8.34315 0 10V10V11V11C0 12.6569 1.34315 14 3 14V14H6V14C7.65685 14 9 12.6569 9 11V11V10.5H7V11V11C7 11.5523 6.55228 12 6 12H3.5C3.03406 12 2.80109 12 2.61732 11.9239C2.37229 11.8224 2.17761 11.6277 2.07612 11.3827C2 11.1989 2 10.9659 2 10.5V10.5C2 10.0341 2 9.80109 2.07612 9.61732C2.17761 9.37229 2.37229 9.17761 2.61732 9.07612C2.80109 9 3.03406 9 3.5 9H13V9C14.6569 9 16 7.65685 16 6V6V5V5Z"></path></svg>
                </>
            );
        case 'Rookie':
            return (
                <>
                    <svg viewBox="0 0 16 16" focusable="false" class="chakra-icon miniIcon" style={{ "max-width": "20px", "max-height": "20px", "display": "block", "margin": "0 auto" }}><path fill="currentColor" d="M15.48 14.176c-.83-.833-3.273-1.438-5.145-1.796l.045.008c-.392-.95-.113-2.388-.113-2.388h-.011c.203-.142.382-.283.483-.417 1.516-1.994 1.581-6.8.508-8.42C10.636.241 8.862 0 8 0S5.364.241 4.753 1.163c-1.073 1.62-1.008 6.426.507 8.42.102.134.281.275.484.417h-.011s.279 1.438-.115 2.388l.047-.008c-1.872.358-4.316.963-5.146 1.796C-.185 14.883.031 16 .031 16h15.938s.216-1.117-.489-1.824z"></path></svg>
                </>
            );
        case 'SportsCar':
            return (
                <>
                    <svg viewBox="0 0 24 24" focusable="false" class="chakra-icon miniIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 10.5H21.6215L20.1215 9H21C21.8284 9 22.5 8.32843 22.5 7.5H18.6215L18.0002 6.87868C17.4376 6.31607 16.6746 6 15.8789 6H8.12155C7.3259 6 6.56284 6.31607 6.00023 6.87868L5.37891 7.5H1.5C1.5 8.32843 2.17157 9 3 9H3.87891L2.37891 10.5H1.5C0.671573 10.5 0 11.1716 0 12V16.5C0 17.3284 0.671573 18 1.5 18H3C3.82843 18 4.5 17.3284 4.5 16.5H19.5C19.5 17.3284 20.1716 18 21 18H22.5C23.3284 18 24 17.3284 24 16.5V12C24 11.1716 23.3284 10.5 22.5 10.5ZM19.5002 10.5H4.50023L7.06066 7.93934C7.34196 7.65803 7.7235 7.5 8.12132 7.5H15.8787C16.2765 7.5 16.658 7.65804 16.9393 7.93934L19.5002 10.5ZM8.56066 12.4393L7.81066 13.1893C7.61175 13.3883 7.5 13.658 7.5 13.9393C7.5 14.5251 7.97487 15 8.56066 15H15.4393C16.0251 15 16.5 14.5251 16.5 13.9393C16.5 13.658 16.3883 13.3883 16.1893 13.1893L15.4393 12.4393C15.158 12.158 14.7765 12 14.3787 12H9.62132C9.2235 12 8.84197 12.158 8.56066 12.4393ZM4.79289 13.2071C4.60536 13.3946 4.351 13.5 4.08579 13.5H3V12H6L4.79289 13.2071ZM21 13.5H19.9142C19.649 13.5 19.3946 13.3946 19.2071 13.2071L18 12H21V13.5Z" fill="currentColor"></path></svg>
                </>
            );
        case 'FormulaCar':
            return (
                <>
                    <svg viewBox="0 0 24 24" focusable="false" class="chakra-icon miniIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.95381 9H7.5C6.67157 9 6 9.67157 6 10.5V12.5L4.5 13V12C4.5 11.1716 3.82843 10.5 3 10.5H1.5C0.671573 10.5 0 11.1716 0 12V16.5C0 17.3284 0.671573 18 1.5 18H3C3.82843 18 4.5 17.3284 4.5 16.5V14.5L6.03931 13.9869C6.27193 15.4122 7.50893 16.5 9 16.5H9.85714L10.0714 18H4.5C4.5 18.8284 5.17157 19.5 6 19.5H10.2857L10.2879 19.5151C10.4096 20.3671 11.1393 21 12 21C12.8607 21 13.5904 20.3671 13.7121 19.5151L13.7143 19.5H18C18.8284 19.5 19.5 18.8284 19.5 18H13.9286L14.1429 16.5H15C16.4911 16.5 17.7281 15.4122 17.9607 13.9869L19.5 14.5V16.5C19.5 17.3284 20.1716 18 21 18H22.5C23.3284 18 24 17.3284 24 16.5V12C24 11.1716 23.3284 10.5 22.5 10.5H21C20.1716 10.5 19.5 11.1716 19.5 12V13L18 12.5V10.5C18 9.67157 17.3284 9 16.5 9H15.0461L14.6711 7.5H19.5C20.3284 7.5 21 6.82843 21 6H14.2961L14.2762 5.92025C14.0675 5.08556 13.3176 4.5 12.4572 4.5H11.5428C10.6824 4.5 9.93242 5.08556 9.72375 5.92025L9.70381 6H3C3 6.82843 3.67157 7.5 4.5 7.5H9.32881L8.95381 9ZM15 10.5H16.5V13.5C16.5 14.3284 15.8284 15 15 15H14.3571L15 10.5ZM10.5 9L13.5 9L12.821 6.28405C12.7793 6.11711 12.6293 6 12.4572 6L11.5428 6C11.3707 6 11.2207 6.11711 11.179 6.28405L10.5 9ZM9 10.5H7.5V13.5C7.5 14.3284 8.17157 15 9 15H9.64286L9 10.5Z" fill="currentColor"></path></svg>
                </>
            );
        case 'Oval':
            return (
                <>
                    <svg viewBox="0 0 24 24" focusable="false" class="chakra-icon miniIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 7.5H6C4.34315 7.5 3 8.84315 3 10.5V11.2918C3 12.4281 3.64201 13.4669 4.65836 13.9751L7.30426 15.298C10.2603 16.776 13.7397 16.776 16.6957 15.298L19.3416 13.9751C20.358 13.4669 21 12.4281 21 11.2918V10.5C21 8.84315 19.6569 7.5 18 7.5ZM6 4.5H18C21.3137 4.5 24 7.18629 24 10.5V11.2918C24 13.5644 22.716 15.642 20.6833 16.6584L18.0374 17.9813C14.2368 19.8816 9.76324 19.8816 5.96262 17.9813L3.31672 16.6584C1.28401 15.642 0 13.5644 0 11.2918V10.5C0 7.18629 2.68629 4.5 6 4.5Z" fill="currentColor"></path></svg>
                </>
            );
        case 'DirtRoad':
            return (
                <>
                    <svg viewBox="0 0 24 24" focusable="false" class="chakra-icon miniIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 3H16C19.7712 3 21.6569 3 22.8284 4.17157C24 5.34315 24 7.22876 24 11V13C24 16.7712 24 18.6569 22.8284 19.8284C21.6569 21 19.7712 21 16 21H8C4.22876 21 2.34315 21 1.17157 19.8284C0 18.6569 0 16.7712 0 13V11C0 7.22876 0 5.34315 1.17157 4.17157C2.34315 3 4.22876 3 8 3ZM6 18H3V9C3 7.34315 4.34315 6 6 6H10.5C12.1569 6 13.5 7.34315 13.5 9V13C13.5 14.1046 14.3954 15 15.5 15H16C17.1046 15 18 14.1046 18 13V6H21V15C21 16.6569 19.6569 18 18 18H13.5C11.8431 18 10.5 16.6569 10.5 15V11C10.5 9.89543 9.60457 9 8.5 9H8C6.89543 9 6 9.89543 6 11V18Z" fill="currentColor"></path></svg>
                </>
            );

        case 'DirtOval':
            return (
                <>
                    <svg viewBox="0 0 24 24" focusable="false" class="chakra-icon miniIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 3H16C19.7712 3 21.6569 3 22.8284 4.17157C24 5.34315 24 7.22876 24 11V13C24 16.7712 24 18.6569 22.8284 19.8284C21.6569 21 19.7712 21 16 21H8C4.22876 21 2.34315 21 1.17157 19.8284C0 18.6569 0 16.7712 0 13V11C0 7.22876 0 5.34315 1.17157 4.17157C2.34315 3 4.22876 3 8 3ZM9 9H15C16.6569 9 18 10.3431 18 12C18 13.6569 16.6569 15 15 15H9C7.34315 15 6 13.6569 6 12C6 10.3431 7.34315 9 9 9ZM15 6H9C5.68629 6 3 8.68629 3 12C3 15.3137 5.68629 18 9 18H15C18.3137 18 21 15.3137 21 12C21 8.68629 18.3137 6 15 6Z" fill="currentColor"></path></svg>
                </>
            );
        case "Overall":
            return (
                <>
                    <svg viewBox="0 0 16 16" focusable="false" class="chakra-icon miniIcon"><path fill="currentColor" d="M15.8,6.9c-0.2-0.2-0.5-0.4-0.8-0.3c-0.6,0.2-0.8-0.1-1.4-0.9c-0.5-0.7-1.2-1.8-2.6-1.7c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.5-0.1-0.7,0.2L8,7.7L5.8,3.9C5.7,3.6,5.4,3.6,5.1,3.7C5,3.8,5,3.9,4.9,4C3.5,3.8,2.8,4.9,2.3,5.6C1.8,6.5,1.5,6.7,1,6.6c-0.3-0.1-0.6,0-0.8,0.3C0,7.1-0.1,7.5,0.1,7.7l1.5,2.6c0.1,0.2,0.3,0.3,0.5,0.4c0.3,0.1,0.5,0.1,0.7,0.1c1.2,0,1.9-1,2.3-1.7C5.7,8.2,5.9,8,6.5,8.1c0.2,0.1,0.4,0,0.6-0.1l0.4,0.6l-1.7,2.9c-0.1,0.2-0.1,0.5,0.2,0.7c0.2,0.1,0.5,0.1,0.7-0.2L8,9.7l1.4,2.4c0.1,0.2,0.4,0.3,0.7,0.2c0.2-0.1,0.3-0.4,0.2-0.7L8.6,8.7l0.4-0.6c0.2,0.1,0.4,0.1,0.6,0.1c0.5-0.2,0.8,0.1,1.4,0.9c0.5,0.7,1.1,1.7,2.3,1.7c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.5-0.4l1.5-2.6C16.1,7.5,16,7.1,15.8,6.9z"></path></svg>
                </>
            );

        default:
            return <></>;
    }
}

export default IRacingIcon;